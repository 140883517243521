"use client";

import { isNullOrUndefined } from "@sailes/utilities";
import { CheckIcon, ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import { Form, Formik } from "formik";
import { useRouter } from "next/navigation";
import { QRCodeCanvas } from "qrcode.react";
import React, { useContext, useState } from "react";
import { useBoolean, useCopyToClipboard } from "usehooks-ts";
import { logger } from "@sailes/logging";
import { AuthenticationContext } from "../../contexts/authentication";
import { mfaSetupSchema } from "../../api/models/mfa-setup-schema";
import { Button, ButtonSize, ButtonVariant } from "../../../common/components/button";
import { TextInput } from "../../../common/components/text-input";
import { useAuthenticationDirector } from "../../hooks/use-authentication-director";
import Link from "next/link";
import { confirmSignIn, getCurrentUser } from "aws-amplify/auth";
import { apiRequest } from "@sailes/api";
import GoogleAuthenticatorLogo from "@sailes/assets/src/logos/google-authenticator";
import MicrosoftAuthenticatorLogo from "@sailes/assets/src/logos/microsoft-authenticator";

export function MfaSetupForm() {
    const [submissionError, setSubmissionError] = useState();
    const { replace } = useRouter();
    const { state } = useContext(AuthenticationContext);
    const isInitialSubmit = useBoolean(true);
    const [copiedText, copyToClipboard] = useCopyToClipboard();
    const { next } = useAuthenticationDirector();

    return (
        <Formik
            validationSchema={mfaSetupSchema}
            initialValues={{
                code: "",
            }}
            validateOnChange={!isInitialSubmit}
            onSubmit={async (values, formikHelpers) => {
                // @TODO - Move this logic to a hook

                try {
                    await confirmSignIn({
                        options: {
                            friendlyDeviceName: "Starboard",
                            userAttributes: {
                                email: state.email,
                            },
                        },
                        challengeResponse: values.code,
                    });

                    const { userId } = await getCurrentUser();

                    const session = await apiRequest<any>({
                        method: "POST",
                        url: "/api/login",
                        data: {
                            id: userId,
                        },
                    });

                    const route = await next(session);
                    replace(route);
                } catch (error: any) {
                    logger.error({ error });
                    formikHelpers.setSubmitting(false);
                    setSubmissionError(error.message);
                    logger.error({ error, message: "Setup MFA error for Cognito User ID" });
                }
            }}
        >
            {(formikProps) => {
                const { values, isSubmitting, setValues, errors } = formikProps;

                return (
                    <Form>
                        <div className="divide-y-2">
                            <div className="grid grid-cols-2 divide-x-2 pb-6">
                                <div className="pr-6">
                                    <div className="mb-8">
                                        <h3 className="flex items-center gap-4 mb-2 text-base md:text-xl font-bold tracking-tight text-stone-950 opacity-95">1. Download</h3>
                                        <p className="text-sm text-stone-600">
                                            These are suggested authenticators and you are welcome to use an existing app, if you already have one.
                                        </p>
                                    </div>
                                    <a
                                        className="flex items-center gap-4 mb-6"
                                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US&pli=1"
                                        target={"_blank"}
                                    >
                                        <div className="rounded-md p-2 shadow-md">
                                            <GoogleAuthenticatorLogo height={40} width={40} />
                                        </div>
                                        <p className="font-semibold text-stone-800">
                                            Google
                                            <br />
                                            Authenticator
                                        </p>
                                    </a>

                                    <a className="flex items-center gap-4" href="https://www.microsoft.com/en-us/security/mobile-authenticator-app" target={"_blank"}>
                                        <div className="rounded-md p-2 shadow-md">
                                            <MicrosoftAuthenticatorLogo height={40} width={40} />
                                        </div>
                                        <p className="font-semibold text-stone-800">
                                            Microsoft
                                            <br />
                                            Authenticator
                                        </p>
                                    </a>
                                </div>
                                <div className="flex justify-center flex-col pl-6">
                                    <div className="mb-8">
                                        <h3 className="flex items-center gap-4 mb-2 text-base md:text-xl font-bold tracking-tight text-stone-950 opacity-95">2. Scan</h3>
                                        <p className="text-sm text-stone-600">
                                            Scan the QR code below with your authenticator app or manually enter the code into your authenticator app.
                                        </p>
                                    </div>
                                    <div className={"mb-4 space-y-4 flex flex-col items-center"}>
                                        <QRCodeCanvas value={state.totpUrl} />
                                        <Button
                                            label={"Copy Code"}
                                            variant={ButtonVariant.link}
                                            leftAside={
                                                isNullOrUndefined(copiedText) ? <ClipboardDocumentIcon /> : <CheckIcon className={"text-green-500 stroke-green-500 stroke-2"} />
                                            }
                                            onClick={() => {
                                                copyToClipboard(state.mfaSecret ?? "");
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="pt-6">
                                <div className="mb-2">
                                    <h3 className="flex items-center gap-4 mb-2 text-base md:text-xl font-bold tracking-tight text-stone-950 opacity-95">3. Enter Code</h3>
                                    <p className="text-sm text-stone-600">Enter the code from the two-factor authenticator app on your phone.</p>
                                </div>

                                <div className="space-y-4">
                                    <TextInput
                                        label={"Code"}
                                        error={errors.code}
                                        type="number"
                                        onChange={(value) =>
                                            setValues({
                                                ...values,
                                                code: value,
                                            })
                                        }
                                        autoFocus={true}
                                    />
                                </div>

                                <div className={"flex flex-col space-y-4 items-center justify-center mt-6"}>
                                    <Button label={"Submit"} busy={isSubmitting} onClick={isInitialSubmit.setFalse} type={"submit"} size={ButtonSize.large} className="w-full" />

                                    <Link href={"/auth/signin"}>
                                        <Button label={"Back to Sign In"} variant={ButtonVariant.link} />
                                    </Link>

                                    {submissionError && <p className="text-sm text-red-500">{submissionError}</p>}
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
