import { isNullOrUndefined } from "@sailes/utilities";
import clsx from "clsx";
import React, { ReactNode } from "react";
import { convertToSnakeCase } from "@sailes/utilities";

export interface FormInputProps {
    children: ReactNode;
    label?: string;
    error?: string;
    helper?: React.ReactNode;
    className?: string;
    labelClassName?: string;
}

export function FormInput(props: FormInputProps) {
    const { label, error, children, className, helper, labelClassName } = props;

    return (
        <div className={className}>
            {!isNullOrUndefined(label) && (
                <label htmlFor={convertToSnakeCase(label ?? "text_input")} className={clsx("block md:text-sm font-semibold text-gray-700 mb-1")}>
                    {label}
                </label>
            )}

            <div className={className}>{children}</div>

            {isNullOrUndefined(error) && !isNullOrUndefined(helper) && (
                <div className={labelClassName}>
                    {typeof helper === "string" ? (
                        <p className="mt-2 text-sm text-gray-400" id={`${label ?? "text_input"}_helper`}>
                            {helper}
                        </p>
                    ) : (
                        <>{helper}</>
                    )}
                </div>
            )}

            <p
                id={`${convertToSnakeCase(label ?? "text_input")}_error`}
                className={clsx("mt-2 text-sm text-red-600 block", isNullOrUndefined(error) ? "hidden" : "block", labelClassName)}
            >
                {error}
            </p>
        </div>
    );
}
