import * as React from "react";
import type { SVGProps } from "react";
const SvgGoogleAuthenticator = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={2500} height={2310} viewBox="0 -11 960 876" {...props}>
        <path
            fill="#1a73e8"
            d="M960 427c0 44.7-36.2 80.9-80.9 80.9H600L480 265.2 609.5 40.9C631.9 2.2 681.3-11 720 11.3c38.7 22.4 51.9 71.8 29.6 110.5L620.1 346.1h259c44.7 0 80.9 36.2 80.9 80.9"
        />
        <path
            fill="#ea4335"
            d="M720 842.7c-38.7 22.3-88.1 9.1-110.5-29.6L480 588.8 350.5 813.1c-22.4 38.7-71.8 51.9-110.5 29.6-38.7-22.4-51.9-71.8-29.6-110.5l129.5-224.3 140.1-5.3 140.1 5.3 129.5 224.3c22.3 38.7 9.1 88.1-29.6 110.5"
        />
        <path fill="#fbbc04" d="m480 265.2-36.5 99.2-103.6-18.3-129.5-224.3c-22.3-38.7-9.1-88.1 29.6-110.5 38.7-22.3 88.1-9.1 110.5 29.6z" />
        <path fill="#34a853" d="m459.1 346.1-93.9 161.8H80.9C36.2 507.9 0 471.7 0 427s36.2-80.9 80.9-80.9z" />
        <path fill="#185db7" d="M620.1 507.9H339.9L480 265.2z" />
    </svg>
);
export default SvgGoogleAuthenticator;
