"use client";

import React from "react";
import { Button, ButtonVariant } from "@sailes/features/common/components/button/button";

export default function Error({ reset }: { error: Error; reset: () => void }) {

    return (

        <div className="-mt-24 gap-4 flex h-screen flex-col items-center justify-center">

            <div className="flex flex-row items-center justify-center gap-x-3 align-middle">

                <h2 className={"text-stone-700"}>
                    Something went wrong while loading this page.
                </h2>

            </div>

            <Button onClick={() => reset()}
                    label="Reload Page"
                    variant={ButtonVariant.secondary} />

        </div>

    );
}
