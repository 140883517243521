"use client";

import clsx from "clsx";
import React, { ReactElement } from "react";
import { isNullOrUndefined } from "@sailes/utilities";
import { ActivityIndicator } from "../activity-indicator";
import { ActivityIndicatorColor } from "../activity-indicator/activity-indicator";

export enum ButtonVariant {
    primary = "PRIMARY",
    outlinedPrimary = "OUTLINED_PRIMARY",
    secondary = "SECONDARY",
    destructive = "DESTRUCTIVE",
    destructiveSecondary = "DESTRUCTIVE_SECONDARY",
    destructiveLink = "DESTRUCTIVE_LINK",
    link = "LINK",
    actionable = "ACTIONABLE",
    beta = "BETA",
    onboardingInfo = "ONBOARDING_INFO",
}

export enum BusyStyle {
    default = "DEFAULT",
    descriptive = "DESCRIPTIVE",
}

export enum ButtonSize {
    extraSmall = "EXTRA_SMALL",
    small = "SMALL",
    large = "LARGE",
}

export enum ButtonShape {
    square = "SQUARE",
    pill = "PILL",
    rounded2xl = "ROUNDED_2XL",
}

export type ButtonType = "submit" | "reset" | "button" | undefined;

export interface ButtonProps {
    label?: string;
    children?: ReactElement;
    labelDescription?: string;
    type?: ButtonType;
    size?: ButtonSize | typeof ButtonSize[keyof typeof ButtonSize] | string;
    shape?: ButtonShape | typeof ButtonShape[keyof typeof ButtonShape] | string;
    variant?: ButtonVariant | typeof ButtonVariant[keyof typeof ButtonVariant] | string;
    disabled?: boolean;
    busy?: boolean;
    busyStyle?: BusyStyle | typeof BusyStyle[keyof typeof BusyStyle] | string;
    className?: string;
    rightAside?: ReactElement | null;
    leftAside?: ReactElement;

    onClick?(e: any): void;
}

export function Button(props: ButtonProps) {
    const { label, disabled, type, onClick, busy, busyStyle = BusyStyle.default, className, shape, rightAside, leftAside, labelDescription } = props;

    const isDisabled = disabled ?? false;
    const isBusy = busy ?? false;
    const variant = props.variant ?? ButtonVariant.primary;
    const size = props.size ?? ButtonSize.small;

    return (
        <button
            type={type ?? "button"}
            disabled={isDisabled || isBusy}
            data-testid={`${label}-button`}
            className={clsx(
                "border",
                stylesForSize(size, variant as ButtonVariant),
                stylesForShape(shape ?? ButtonShape.square),
                stylesForVariant(variant as ButtonVariant),
                isDisabled && disabledStylesForVariant(variant as ButtonVariant),
                isBusy && busyStylesForVariant(variant as ButtonVariant),
                className
            )}
            onClick={onClick}
        >
            <div className={clsx("relative flex flex-row gap-0 justify-center", size === ButtonSize.large ? "gap-x-3" : "gap-x-1")}>
                <div
                    className={clsx(
                        busyStyle === BusyStyle.default ? "order-1" : "order-2",
                        "flex flex-row justify-center items-center",
                        !isBusy ? "visible" : busyStyle === BusyStyle.descriptive ? "visible" : "invisible",
                        size === ButtonSize.large ? "gap-x-3" : "gap-x-1"
                    )}
                >
                    {!isNullOrUndefined(leftAside) && !isBusy && <div className={asideStylesForSize(size)}>{leftAside}</div>}

                    {props.children ? props.children : <div>{label}</div>}

                    <span className="sr-only">
                        {label}, {labelDescription}
                    </span>

                    {!isNullOrUndefined(rightAside) && !isBusy && <div className={asideStylesForSize(size)}>{rightAside}</div>}
                </div>

                {isBusy && (
                    <div
                        className={clsx(
                            busyStyle === BusyStyle.descriptive ? clsx(asideStylesForSize(size), isNullOrUndefined(leftAside) ? 'order-2' : 'order-1') : "absolute left-0 right-0",
                            busyStyle === BusyStyle.default ? "order-2": ''
                        )}
                    >
                        <ActivityIndicator color={activityIndicatorStylesForVariant(variant as ButtonVariant)} />
                    </div>
                )}
            </div>
        </button>
    );
}

function stylesForShape(shape: ButtonShape | string): string {
    switch (shape) {
        case ButtonShape.square:
            return "rounded-md";
        case ButtonShape.pill:
            return "rounded-full";
        case ButtonShape.rounded2xl:
            return "rounded-2xl";
        default:
            return "";
    }
}

function stylesForVariant(variant: ButtonVariant): string {
    switch (variant) {
        case ButtonVariant.primary:
            return clsx(
                "bg-stone-800 text-white shadow-sm border-transparent hover:bg-stone-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-800"
            );
        case ButtonVariant.beta:
            return clsx(
                "border-indigo-600 text-indigo-600 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            );
        case ButtonVariant.secondary:
            return clsx(
                "bg-white text-stone-800 border-gray-300 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-800"
            );
        case ButtonVariant.onboardingInfo:
            return clsx(
                "bg-[#F6F6F4] text-stone-800 border-gray-300 shadow-sm hover:bg-red-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-800"
            );
        case ButtonVariant.destructiveSecondary:
            return clsx(
                "border-red-300 text-red-600 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            );
        case ButtonVariant.outlinedPrimary:
            return clsx(
                "bg-white border-stone-700 text-stone-800 shadow-sm hover:bg-gray-50 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-800"
            );
        case ButtonVariant.destructive:
            return clsx(
                "bg-red-600 text-white shadow-sm border-transparent hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            );
        case ButtonVariant.destructiveLink:
            return clsx("text-red-500 underline font-sans font-medium text-sm border-none hover:cursor-pointer hover:text-red-600 ");
        case ButtonVariant.link:
            return clsx(
                "text-stone-800 rounded-0 border-0 hover:text-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-800"
            );
        case ButtonVariant.actionable:
            return clsx("h-7 w-28 text-stone-800 text-opacity-90 font-medium bg-gray-200 hover:bg-gray-300");
        default:
            return clsx("text-gray-600 rounded-0 border-0 hover:text-gray-900");
    }
}

function disabledStylesForVariant(variant: ButtonVariant): string {
    switch (variant) {
        case ButtonVariant.destructiveLink:
            return "text-stone-400 underline font-sans font-medium text-sm border-none";
        default:
            return "cursor-not-allowed hover:none opacity-50";
    }
}

function busyStylesForVariant(variant: ButtonVariant): string {
    switch (variant) {
        case ButtonVariant.primary:
            return clsx("bg-stone-800 hover:bg-stone-800");
        case ButtonVariant.destructive:
            return clsx("bg-red-600 hover:bg-red-600");
        case ButtonVariant.onboardingInfo:
            return clsx("bg-red-100 hover:bg-red-100");
        case ButtonVariant.secondary:
        case ButtonVariant.destructiveSecondary:
            return "hover:bg-white";
        default:
            return "";
    }
}

function stylesForSize(size: ButtonSize | string, variant: ButtonVariant | string): string {
    switch (size) {
        case ButtonSize.small:
            return clsx("text-sm text-stone-800 font-semibold", variant === ButtonVariant.link || "p-2.5");
        case ButtonSize.large:
            return clsx("text-base text-stone-800 font-semibold", variant === ButtonVariant.link || "px-4 py-2");

        case ButtonSize.extraSmall:
            return clsx("text-sm text-stone-800 font-medium px-2 py-1 font-sans", variant === ButtonVariant.actionable || "px-2 py-1");

        default:
            return clsx("md:text-sm text-stone-800 font-medium", variant === ButtonVariant.link || "px-4 py-2 md:px-3 md:py-1");
    }
}

function activityIndicatorStylesForVariant(variant: ButtonVariant): ActivityIndicatorColor {
    switch (variant) {
        case ButtonVariant.link:
        case ButtonVariant.secondary:
            return ActivityIndicatorColor.black;
        case ButtonVariant.destructiveSecondary:
            return ActivityIndicatorColor.red;
        case ButtonVariant.destructiveLink:
        case ButtonVariant.outlinedPrimary:
            return ActivityIndicatorColor.black;
        default:
            return ActivityIndicatorColor.white;
    }
}

function asideStylesForSize(size: ButtonSize | string): string {
    switch (size) {
        case ButtonSize.large:
            return "overflow-hidden w-5 h-5";
        default:
            return "overflow-hidden w-5 h-5";
    }
}
