import { AuthenticationState } from "./authentication-state";

export enum AuthenticationActionType {
    login,
    updateEmail,
    updateMfaSecret
}

export interface AuthenticationAction {
    type: AuthenticationActionType
    payload?: Partial<AuthenticationState>
}
