import { object, ref, string } from 'yup';

export const newPasswordSchema = object().shape({
    password: string()
        .required('Password is required.')
        .min(8, 'Password must be 8 characters long.')
        .matches(/[0-9]/, 'Password requires a number.')
        .matches(/[a-z]/, 'Password requires a lowercase letter.')
        .matches(/[A-Z]/, 'Password requires an uppercase letter.'),
    confirmPassword: string()
        .oneOf([ref('password'), undefined], 'Passwords must match.'),
})