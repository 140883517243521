import { ApiErrorStatusCode } from "./api-error-status-code";

interface IApiError {
    /**
     * @description code representation of error, can be used to determine what to display if not detail is provided
     */
    readonly code: ApiErrorStatusCode | string;
    /**
     * @description detailed text information about the error
     */
    readonly detail: string;
    /**
     * @description additional information about the error
     */
    readonly meta?: object;
}

/**
 * Custom error class
 */
export class ApiError extends Error {
    readonly code: ApiErrorStatusCode | string;
    readonly detail: string;
    readonly meta?: object;

    /**
     * @description Custom error class
     * @param statusCode
     * @param message
     * @param meta
     */
    constructor(statusCode: ApiErrorStatusCode | string, message: string, meta?: object) {
        super(message);
        this.code = statusCode;
        this.detail = message;
    }

    /**
     * Convert the error to an object
     */
    toObject(): IApiError {
        return {
            code: this.code,
            detail: this.detail,
            meta: this.meta,
        };
    }

    /**
     * Convert the error to JSON
     */
    toJSON(): string {
        return JSON.stringify(this.toObject());
    }
}
