"use client";

import React, { ReactNode } from "react";
import { useRumInit } from "@sailes/features/analytics/hooks/use-rum-init";
import { useSessionRecording } from "@sailes/features/analytics/hooks/use-session-recording";

interface ProvidersProps {
    children: ReactNode;
}

export default function Providers(props: ProvidersProps) {

    const { children } = props

    useRumInit(false)
    useSessionRecording()

    return (
        <>
            {children}
        </>
    )

}
