import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { delay } from "@sailes/utilities";

interface RetryConfig extends AxiosRequestConfig {
    retry: number;
    retryDelay: number;
}

export type RequestConfig = AxiosRequestConfig | RetryConfig;

const api = axios.create({
    timeout: 60000,
});

axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig<RequestConfig>) => {
        return config;
    },
    async (error: any) => {
        const { config, message, response } = error;

        if (!config || !config.retry) {
            return Promise.reject(error);
        }

        if (!(message.includes("timeout") || message.includes("Network Error") || response?.data?.includes("JWTExpired"))) {
            return Promise.reject(error);
        }

        config.retry -= 1;

        await delay(config.retryDelay || 1000);

        return axios(config);
    }
);

export const apiRequest = async <T>(config: RequestConfig): Promise<T> => {
    const response = await api(config);
    return response.data;
};
