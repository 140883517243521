"use client";

import { Form, Formik } from "formik";
import { useRouter, useSearchParams } from "next/navigation";
import React, { useContext, useState } from "react";
import { useBoolean } from "usehooks-ts";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { AuthenticationActionType, AuthenticationContext } from "../../contexts/authentication";
import { loginSchema } from "../../api/models/login-schema";
import { TextInput } from "../../../common/components/text-input";
import { Button, ButtonSize, ButtonVariant } from "../../../common/components/button";
import { logger } from "@sailes/logging";
import { obfuscateEmail } from "@sailes/utilities";
import { getCurrentUser, signIn, signOut } from "aws-amplify/auth";
import { Amplify } from "aws-amplify";
import { getAuthConfiguration } from "@sailes/aws-exports";
import MailIcon from "@sailes/assets/src/icons/mail";
import { apiRequest } from "@sailes/api";
import { useAuthenticationDirector } from "../../hooks/use-authentication-director";

const config = getAuthConfiguration();

Amplify.configure(config, { ssr: true });

export function LoginForm() {
    const [submissionError, setSubmissionError] = useState("");
    const { push, replace } = useRouter();
    const searchParams = useSearchParams();
    const { state, dispatch } = useContext(AuthenticationContext);
    const isInitialSubmit = useBoolean(true);
    const { next } = useAuthenticationDirector();
    /// for some reason just using the formik `isSubmitting` flag is not moving the button into submitting mode,
    //      mainly because I don't think it is re-rendering when it should, need to look into this further
    const isLoggingIn = useBoolean(false);

    return (
        <Formik
            validationSchema={loginSchema}
            initialValues={{
                email: state.email ?? "",
                password: "",
                rememberMe: false,
            }}
            validateOnChange={!isInitialSubmit.value}
            onSubmit={async (values, formikHelpers) => {
                isLoggingIn.setTrue();

                try {
                    const { nextStep } = await signIn({
                        username: values.email,
                        password: values.password,
                        options: {
                            authFlowType: "USER_SRP_AUTH",
                            clientMetadata: {
                                email: values.email,
                            },
                        },
                    });

                    logger.debug({ email: obfuscateEmail(values.email), message: `User's next step in auth ${nextStep.signInStep}` });

                    if (nextStep.signInStep === "RESET_PASSWORD") {
                        dispatch({
                            type: AuthenticationActionType.updateEmail,
                            payload: {
                                email: values.email,
                            },
                        });

                        replace(`/auth/reset-password?callbackUrl=${searchParams?.get("callbackUrl") ?? "/"}`);
                        return;
                    }
                    if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
                        replace(`/auth/new-password?callbackUrl=${searchParams?.get("callbackUrl") ?? "/"}`);
                    } else if (nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_TOTP_SETUP") {
                        dispatch({
                            type: AuthenticationActionType.updateMfaSecret,
                            payload: {
                                mfaSecret: nextStep.totpSetupDetails.sharedSecret,
                                totpUrl: nextStep.totpSetupDetails.getSetupUri("Starboard", values.email).href,
                            },
                        });
                        replace(`/auth/mfa-setup?callbackUrl=${searchParams?.get("callbackUrl") ?? "/"}`);
                    } else if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
                        replace(`/auth/mfa-confirm?callbackUrl=${searchParams?.get("callbackUrl") ?? "/"}`);
                    } else if (nextStep.signInStep === "DONE") {
                        const { userId } = await getCurrentUser();

                        const session = await apiRequest<any>({
                            method: "POST",
                            url: "/api/login",
                            data: {
                                id: userId,
                            },
                        });
                        const route = await next(session);

                        replace(route);
                    }
                } catch (error) {
                    // In some cases the user is already authenticated, so we need to sign them out and have the user try again
                    //     this is mainly for the case where we don't have a starboard session but for some reason we have a cognito session.
                    //     I have seen this happen locally, but not in production, so this is just a precaution
                    if (error.name === "UserAlreadyAuthenticatedException") {
                        await signOut();
                    }

                    if (error.name === "PasswordResetRequiredException") {
                        replace(`/auth/reset-password?callbackUrl=${searchParams?.get("callbackUrl") ?? "/"}`);
                        return;
                    }

                    isLoggingIn.setFalse();

                    logger.error({ error, email: values.email });

                    dispatch({
                        type: AuthenticationActionType.login,
                        payload: {
                            email: values.email,
                        },
                    });

                    // if (error.code === ) {
                    //     push(`/auth/reset-password?callbackUrl=${searchParams?.get("callbackUrl") ?? "/"}`);
                    //     return;
                    // }

                    if (error.name === "NotAuthorizedException") {
                        setSubmissionError("Username or Password entered is not correct.");
                    } else {
                        setSubmissionError("Something went wrong. Please try again.");
                    }
                    formikHelpers.setSubmitting(false);
                    logger.error({ error, message: `Login Failed ${obfuscateEmail(values.email) ?? ""}`, email: obfuscateEmail(values.email) });
                }
            }}
        >
            {(formikProps) => {
                const { values, isSubmitting, setValues, errors, initialValues, setSubmitting } = formikProps;

                return (
                    <Form>
                        <div className="space-y-4">
                            <TextInput
                                label={"Email"}
                                type="email"
                                defaultValue={initialValues.email}
                                error={errors.email}
                                autoComplete="on"
                                onChange={(value) =>
                                    setValues({
                                        ...values,
                                        email: value,
                                    })
                                }
                                leftAdornment={<MailIcon className={"pt-0.5"} />}
                            />

                            <TextInput
                                label={"Password"}
                                type="password"
                                error={errors.password}
                                autoComplete="on"
                                onChange={(value) =>
                                    setValues({
                                        ...values,
                                        password: value,
                                    })
                                }
                                leftAdornment={<LockClosedIcon className="text-gray-900 opacity-40" />}
                            />

                            <div className="flex items-center justify-end">
                                <Button
                                    variant={ButtonVariant.link}
                                    label={"Forgot your password?"}
                                    onClick={() => {
                                        dispatch({
                                            type: AuthenticationActionType.updateEmail,
                                            payload: {
                                                email: values.email,
                                            },
                                        });
                                        push(`/auth/forgot-password?callbackUrl=${searchParams?.get("callbackUrl") ?? "/"}`);
                                    }}
                                />
                            </div>
                        </div>

                        <div className={"flex flex-col space-y-6 items-center justify-center mt-10"}>
                            <Button
                                label={"Sign in"}
                                busy={isSubmitting || isLoggingIn.value}
                                onClick={isInitialSubmit.setFalse}
                                type={"submit"}
                                size={ButtonSize.large}
                                className={"w-full"}
                                variant={ButtonVariant.primary}
                            />

                            {submissionError && <p className={"text-sm text-red-500"}>{submissionError}</p>}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
