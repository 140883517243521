"use client";

import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import { useBoolean } from "usehooks-ts";
import { logger } from "@sailes/logging";
import { TextInput } from "../../../common/components/text-input";
import { Button, ButtonSize, ButtonVariant } from "../../../common/components/button";
import { AuthenticationActionType, AuthenticationContext } from "../../contexts/authentication";
import { resetPasswordSchema } from "../../api/models/reset-password-schema";
import { confirmResetPassword } from "aws-amplify/auth";
import { obfuscateEmail } from "@sailes/utilities";

export function ResetPasswordForm() {
    const [submissionError, setSubmissionError] = useState("");
    const searchParams = useSearchParams();
    const { replace, back } = useRouter();
    const { state, dispatch } = useContext(AuthenticationContext);
    const isInitialSubmit = useBoolean(true);

    return (
        <Formik
            validationSchema={resetPasswordSchema}
            initialValues={{
                verificationCode: "",
                newPassword: "",
                email: state.email ?? "",
            }}
            validateOnChange={!isInitialSubmit}
            onSubmit={async (values, formikHelpers) => {
                try {
                    await confirmResetPassword({
                        confirmationCode: values.verificationCode,
                        newPassword: values.newPassword,
                        username: values.email,
                    });

                    dispatch({
                        type: AuthenticationActionType.updateEmail,
                        payload: {
                            email: values.email,
                        },
                    });

                    replace(`/auth/signin?callbackUrl=${searchParams?.get("callbackUrl") ?? "/"}`);
                } catch (error) {
                    logger.error({
                        error,
                        email: obfuscateEmail(values.email),
                        message: error.message,
                        domain: "packages/features/authentication/components/reset-password-form/reset-password-form.tsx",
                    });
                    formikHelpers.setSubmitting(false);
                    setSubmissionError("Invalid code. Please try again.");
                }
            }}
        >
            {(formikProps) => {
                const { values, isSubmitting, setValues, errors, initialValues } = formikProps;

                return (
                    <Form>
                        <div className="space-y-4">
                            <TextInput
                                label={"Email"}
                                error={errors.email}
                                type="email"
                                defaultValue={initialValues.email}
                                onChange={(value) => {
                                    setValues({
                                        ...values,
                                        email: value,
                                    });
                                }}
                            />

                            <TextInput
                                label={"Verification Code"}
                                error={errors.verificationCode}
                                type="number"
                                onChange={(value) =>
                                    setValues({
                                        ...values,
                                        verificationCode: value,
                                    })
                                }
                            />

                            <TextInput
                                label={"New Password"}
                                error={errors.newPassword}
                                type="password"
                                onChange={(value) =>
                                    setValues({
                                        ...values,
                                        newPassword: value,
                                    })
                                }
                            />
                        </div>

                        <div className={"flex flex-col space-y-6 items-center justify-center mt-10"}>
                            <Button label={"Reset Password"} busy={isSubmitting} onClick={isInitialSubmit.setFalse} type={"submit"} size={ButtonSize.large} className="w-full" />

                            <Button label={"Back to forgot password"} onClick={back} variant={ButtonVariant.link} />

                            {submissionError && <p className="text-sm text-red-500">{submissionError}</p>}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
