import { Dispatch, createContext } from "react";
import { AuthenticationState, authenticationInitialState } from "./authentication-state";

import { AuthenticationAction } from "./authentication-actions";
import { noop } from "@sailes/utilities";

export const AuthenticationContext = createContext<{ state: AuthenticationState, dispatch: Dispatch<AuthenticationAction> }>({
    state: authenticationInitialState,
    dispatch: noop
});
