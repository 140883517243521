import { object, string } from "yup";

export const resetPasswordSchema = object().shape({
    email: string().email("Invalid email address.").required("Email is required."),
    verificationCode: string().required("Verification code is required."),
    newPassword: string()
        .required("Password is required.")
        .min(8, "Password must be 8 characters long.")
        .matches(/[0-9]/, "Password requires a number.")
        .matches(/[a-z]/, "Password requires a lowercase letter.")
        .matches(/[A-Z]/, "Password requires an uppercase letter."),
});
