import { isEmpty } from "./is-empty";

export function obfuscateEmail(email: string | undefined): string {
    if (isEmpty(email) || email === undefined) {
        return "";
    }
    const [name, domain] = email.split("@");
    const obfuscatedName = name.slice(0, 2);
    const obfuscatedDomain = domain.slice(0, 2);
    return `${obfuscatedName}@${obfuscatedDomain}`;
}

export function extractEmailDomain(input: string): string | null {
    const email = input.trim()
    const emailRegex = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const match = email.match(emailRegex);

    if (match) {
        return match[1]; // The captured domain is in the first capture group.
    }

    return null; // Return null if the email doesn't match the expected format.
}