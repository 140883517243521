import { isNullOrUndefined } from "./is-null-or-undefined";

export const isObject = (value: any): value is object => {
    return typeof value === "object" && !isArray(value);
}

export const isString = (value: any) => {
    if(isNullOrUndefined(value)) {
        return false;
    }
    return typeof value === "string";
}

export const isNumber = (value: any): value is number => {
    return typeof value === "number"
};

export const isArray = (value: any): value is any[] => {
    return Array.isArray(value);
}