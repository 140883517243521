import * as React from "react";
import type { SVGProps } from "react";
const SvgMicrosoftAuthenticator = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 400 400" {...props}>
        <linearGradient id="microsoft-authenticator_svg__a" x1={201.074} x2={201.074} y1={400} y2={257.767} gradientUnits="userSpaceOnUse">
            <stop
                offset={0.001}
                style={{
                    stopColor: "#1caced",
                }}
            />
            <stop
                offset={0.999}
                style={{
                    stopColor: "#40c5f3",
                }}
            />
        </linearGradient>
        <path
            d="M96.05 336.92c2.55-7.93 5.96-15.5 9.56-22.99 3.23-6.72 8.02-12.37 12.82-17.94 10.64-12.35 23.42-21.91 38.32-28.65 16.96-7.67 34.81-10.68 53.22-9.21 23.37 1.87 44.27 10.27 62.23 25.6 16.15 13.79 27.51 30.74 33.9 51.01-5.24 6.7-11.13 12.77-17.52 18.39-5.18 4.55-10.02 9.49-15.48 13.73-12.5 9.69-24.99 19.52-39.63 25.76-27.01 11.5-53.35 9.57-78.43-5.79-20.92-12.81-39.71-28.29-56.2-46.5-.99-1.09-1.87-2.27-2.79-3.41"
            style={{
                fill: "url(#microsoft-authenticator_svg__a)",
            }}
        />
        <linearGradient id="microsoft-authenticator_svg__b" x1={201.422} x2={201.422} y1={240.803} y2={131.286} gradientUnits="userSpaceOnUse">
            <stop
                offset={0.001}
                style={{
                    stopColor: "#0b9fe9",
                }}
            />
            <stop
                offset={0.999}
                style={{
                    stopColor: "#3fc4f5",
                }}
            />
        </linearGradient>
        <path
            d="M256.12 186.08c.83 29.31-24.83 55.73-56.28 54.7-28.94-.95-53.43-24.42-53.14-55.37.28-29.72 24.81-54.32 54.84-54.12 30.33.2 54.92 24.73 54.58 54.79"
            style={{
                fill: "url(#microsoft-authenticator_svg__b)",
            }}
        />
        <linearGradient id="microsoft-authenticator_svg__c" x1={200} x2={200} y1={336.918} y2={0} gradientUnits="userSpaceOnUse">
            <stop
                offset={0}
                style={{
                    stopColor: "#125a9e",
                }}
            />
            <stop
                offset={0.999}
                style={{
                    stopColor: "#00a0ed",
                }}
            />
        </linearGradient>
        <path
            d="M337.91 121.13c-6.76-5.63-14.04-10.22-22.4-12.98-1.32-.43-1.71-1.1-1.87-2.31-.42-3.29-.86-6.58-1.39-9.86-3.89-24.16-14-45.38-31.32-62.67C253.69 6.13 220.31-4.42 182.7 1.68c-46.12 7.48-75.69 35.14-90.04 79.35-2.4 7.4-3.72 15.04-4.44 22.78-.2 2.12-.78 3.47-3.27 4.26-11.08 3.53-20.72 9.5-28.7 18.04-11.98 12.82-18.63 28.01-19 45.52-.56 26.5 3.18 52.55 10.7 77.93 7.29 24.59 18.13 47.6 33.1 68.54 4.67 6.53 9.42 13.02 15.01 18.83 2.55-7.93 5.96-15.5 9.56-22.99 3.23-6.72 8.02-12.37 12.82-17.94 10.64-12.35 23.42-21.91 38.32-28.65 16.96-7.67 34.81-10.68 53.22-9.21 23.37 1.87 44.27 10.27 62.23 25.6 16.15 13.79 27.51 30.74 33.9 51.01.28-.23.61-.42.84-.69 14.02-16.57 25.35-34.82 34.36-54.57 6.84-15.01 11.64-30.67 15.28-46.69 4.33-19.01 6.36-38.3 6.23-57.79-.15-21.7-8.05-39.82-24.91-53.88M199.85 240.77c-28.94-.95-53.43-24.42-53.14-55.37.28-29.72 24.81-54.32 54.84-54.12 30.32.2 54.91 24.73 54.58 54.79.83 29.31-24.83 55.74-56.28 54.7m-65.73-140.15c5.43-25.78 30.09-53.54 65.64-53.98 34.9-.43 61 25.83 67.41 54.12-44.37-4.11-88.69-4.13-133.05-.14"
            style={{
                fill: "url(#microsoft-authenticator_svg__c)",
            }}
        />
        <path
            d="M134.58 100.61c-15.47 1.41-30.92 3.01-46.16 6.22 1.78-20.1 7.47-38.94 18.57-55.91 17.68-27.03 42.46-43.58 74.32-49.06 27.39-4.71 53.46-.51 77.5 13.7 28.39 16.77 45.36 41.93 52.88 73.77 1.4 5.94 2 12 2.73 18.04-10.66-2.52-21.44-4.12-32.36-5.11-4.74-.43-9.51-.88-14.24-1.58 0-.09.01-.19-.01-.28-8.02-32.85-39.17-58.4-76.25-53.09-28.91 4.12-50.98 27.27-56.98 53.3"
            style={{
                fill: "#205693",
            }}
        />
    </svg>
);
export default SvgMicrosoftAuthenticator;
