import React, { ReactNode, useReducer } from "react";
import { AuthenticationContext } from "./authentication-context";
import { authenticationInitialState } from "./authentication-state";
import { authenticationReducer } from "./authentication-reducer";

interface AuthenticationProviderProps {
    children: ReactNode;
    app?: "portside" | "starboard";
}

export function AuthenticationProvider(props: AuthenticationProviderProps) {
    const [state, dispatch] = useReducer(authenticationReducer, { ...authenticationInitialState, app: props.app });

    return <AuthenticationContext.Provider value={{ state: state, dispatch: dispatch }}>{props.children}</AuthenticationContext.Provider>;
}
