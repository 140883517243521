/**
 * The model identifier to use for completion.
 */
export enum OpenAiModel {
    gpt35Turbo = "gpt-3.5-turbo",
    gpt35Turbo16k = "gpt-3.5-turbo-16k",
    gpt4 = "gpt-4",
    gpt4Turbo = "gpt-4-turbo",
}

/**
 * The configuration for a completion request.
 */
export interface CompletionConfig {
    /**
     * How much to penalize new tokens based on their existing frequency in the text so far. Decreases the model's likelihood to repeat the same line verbatim.
     * @minimum 0
     * @maximum 2
     */
    frequency_penalty: number;
    /**
     * The maximum number of tokens to generate shared between the prompt and completion. The exact limit varies by model. (One token is roughly 4 characters for standard English text)
     */
    max_tokens: number;
    /**
     * The list of messages that will be used to generate the request response.
     */
    messages?: string[];
    /**
     * The model identifier to use for completion.
     * @default "gpt-3.5-turbo"
     */
    model: OpenAiModel;
    /**
     * How much to penalize new tokens based on whether they appear in the text so far. Increases the model's likelihood to talk about new topics.
     * @default 1
     * @minimum 0
     * @maximum 2
     */
    presence_penalty: number;
    /**
     * whether to stream back partial progress
     */
    stream?: boolean;
    /**
     * Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive.
     * @default 1
     * @minimum 0
     * @maximum 2
     */
    temperature: number;
    /**
     * Controls diversity via nucleus sampling: 0.5 means half of all likelihood-weighted ontions are considered
     * @default 1
     */
    top_p: number;
}

/**
 * The default completion configuration.
 * @default
 * {
 *    model: "gpt-3.5-turbo",
 *    temperature: 1,
 *    max_tokens: 256,
 *    top_p: 1,
 *    frequency_penalty: 1,
 *    presence_penalty: 1,
 *    stream: false,
 *    messages: [],
 * }
 */
export const defaultCompletionConfig: CompletionConfig = {
    model: OpenAiModel.gpt4Turbo,
    temperature: 1,
    max_tokens: 256,
    top_p: 1,
    frequency_penalty: 1,
    presence_penalty: 1,
    stream: false,
    messages: [],
};
