"use client";

import { Form, Formik } from "formik";
import { useRouter, useSearchParams } from "next/navigation";
import React, { useState } from "react";
import { useBoolean } from "usehooks-ts";
import { logger } from "@sailes/logging";
import { useAuthenticationDirector } from "../../hooks/use-authentication-director";
import { mfaConfirmSchema } from "../../api/models/mfa-confirm-schema";
import { TextInput } from "../../../common/components/text-input";
import { Button, ButtonSize, ButtonVariant } from "../../../common/components/button";
import { confirmSignIn, getCurrentUser } from "aws-amplify/auth";
import { apiRequest } from "@sailes/api";

export function MfaConfirmForm() {
    const [submissionError, setSubmissionError] = useState();
    const { replace } = useRouter();
    const searchParams = useSearchParams();
    const isInitialSubmit = useBoolean(true);
    const { next } = useAuthenticationDirector();

    return (
        <Formik
            validationSchema={mfaConfirmSchema}
            initialValues={{
                code: "",
            }}
            validateOnChange={!isInitialSubmit}
            onSubmit={async (values, formikHelpers) => {
                try {
                    await confirmSignIn({
                        challengeResponse: values.code,
                    });

                    const { userId } = await getCurrentUser();

                    const session = await apiRequest<any>({
                        method: "POST",
                        url: "/api/login",
                        data: {
                            id: userId,
                        },
                    });

                    const route = await next(session);

                    replace(route);
                } catch (error: any) {
                    logger.error({ error });
                    formikHelpers.setSubmitting(false);
                    setSubmissionError(error.message);
                    logger.error({ error, message: "Confirm MFA error for Cognito User ID" });
                }
            }}
        >
            {(formikProps) => {
                const { values, isSubmitting, setValues, errors } = formikProps;

                return (
                    <Form>
                        <div className="space-y-4">
                            <TextInput
                                label={"Code"}
                                error={errors.code}
                                type="number"
                                autoComplete="on"
                                onChange={(value) =>
                                    setValues({
                                        ...values,
                                        code: value,
                                    })
                                }
                                autoFocus={true}
                            />
                        </div>

                        <div className={"flex flex-col space-y-6 items-center justify-center mt-10"}>
                            <Button label={"Submit Code"} busy={isSubmitting} onClick={isInitialSubmit.setFalse} type={"submit"} size={ButtonSize.large} className="w-full" />

                            <Button label={"Back to Sign In"} onClick={() => replace(`/auth/signin?${searchParams}`)} variant={ButtonVariant.link} className="w-full" />

                            {submissionError && <p className="text-sm text-red-500">{submissionError}</p>}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
