import { isNullOrUndefined } from "./is-null-or-undefined";
import { isEmpty } from "./is-empty";

export const convertToSnakeCase = (str: string): string => {
    if (isNullOrUndefined(str) || isEmpty(str)) {
        return str;
    }

    return str!
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)!
        .map((x) => x.toLowerCase())
        .join("_");
};

export const convertToDashCase = (str: string): string => {
    if (isNullOrUndefined(str) || isEmpty(str)) {
        return str;
    }

    return str!
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)!
        .map((x) => x.toLowerCase())
        .join("-");
};

export const convertToCamelCase = (str: string): string => {
    // remove special characters, spaces, etc.
    str = str.replace(/[^a-zA-Z0-9 ]/g, "");
    let working = str
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, "");
    working = working.charAt(0).toLowerCase() + working.slice(1);
    return working;
};

export const convertToSpaceSeparatedWords = (str: string): string => {
    if (isNullOrUndefined(str) || isEmpty(str)) {
        return str;
    }

    return str!
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)!
        .map((x) => x.toLowerCase())
        .join(" ");
};

export const replaceControversialWords = (str: string): string => {
    if (isNullOrUndefined(str) || isEmpty(str)) {
        return str;
    }

    return str!.replace(/black\s?list/gi, "suppression list").replace(/white\s?list/gi, "allow list");
};

export const convertWordsToUpperCase = (str: string): string => {
    if (isNullOrUndefined(str) || isEmpty(str)) {
        return str;
    }

    // if the string contains a hyphenated word then convert each part to uppercase
    return str!
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join("-")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

export const convertToHash = (str: string): string => {
    if (isNullOrUndefined(str) || isEmpty(str)) {
        return str;
    }

    let hash = btoa(str); // create a base-64 encoded string
    hash = hash.replace(/=/g, ""); // remove any trailing '='s

    // if the length is less than 10, then repeat the string until it is 10 characters long
    while (hash.length < 10) {
        hash += hash;
    }

    // create a new string from the first 5 characters and the last 5 characters of the hash
    return hash.slice(0, 5) + hash.slice(-5);
};

export const splitIntoParagraphs = (str: string): string[] => {
    // if the string is null or undefined, or empty, then return an empty array
    if (isNullOrUndefined(str) || isEmpty(str)) {
        return [];
    }

    // remove any leading or trailing \n characters
    str = str.replace(/^\n+|\n+$/g, "");

    // replace multiple new lines with single new line
    str = str.replace(/\n+/g, "\n");

    const paragraphs = str.split("\n");

    // trim each paragraph
    return paragraphs.map((paragraph) => paragraph.trim());
};

export const sanitizeJsonString = (str: string): string => {
    // if the string is null or undefined, or empty, then return the original string
    if (isNullOrUndefined(str) || isEmpty(str)) {
        return str;
    }

    // if the string is valid JSON, then return the original string
    try {
        JSON.parse(str);
        return str;
    } catch (e) {
        let sanitized = str;

        // remove non-breaking spaces
        sanitized = sanitized.replace(/\u00a0/g, " ");

        // remove new lines and tabs
        sanitized = sanitized.replace(/\n/g, " ").replace(/\t/g, " ");

        sanitized = ensureDoubleQuotes(sanitized);
        sanitized = removeTrailingCommas(sanitized);

        return sanitized;
    }
};

const removeTrailingCommas: (str: string) => string = (str) => {
    // remove trailing from json string
    const regex = /,(?=\s*?[\}\]])/g;
    return str.replace(regex, "");
};

const ensureDoubleQuotes: (str: string) => string = (str) => {
    // check to make sure all keys are wrapped in double quotes
    const regex = /([{,])(\s*)([A-Za-z0-9_\-]+?)\s*:/g;
    const count = (str.match(regex) || []).length;

    // if the count is 0, then return the original string
    if (count === 0) {
        return str;
    } else {
        // otherwise, return the string with the keys wrapped in double quotes
        return sanitizeJsonString(str.replace(regex, '$1"$3":'));
    }
};

export const convertInputArrayToPGString = (array: string[]): string => {
    if (isNullOrUndefined(array) || isEmpty(array)) {
        return "{}";
    } else {
        const trimmedString = array
            .map((str) => str.trim())
            .filter((str) => str !== "")
            .join(",");
        return "{" + trimmedString + "}";
    }
};

export const convertPGStringToInputArray = (str: string): string[] => {
    if (isNullOrUndefined(str) || isEmpty(str)) {
        return [];
    } else {
        const trimmedString = str.replace("{", "").replace("}", "").trim();
        const trimmedArray = trimmedString.split(",");
        return trimmedArray.filter((str) => str !== "").map((str) => str.trim());
    }
};

export const convertPGArrayToInputArray = (array: string[]): string[] => {
    if (isNullOrUndefined(array) || isEmpty(array)) {
        return [];
    } else {
        const trimmedString = array.map((str) => str.replace("{", "").replace("}", "").trim());
        return trimmedString.filter((str) => str !== "");
    }
};

export const removeHttpsFromUrl = (url: string) => {
    return url.replace(/^https?:\/\//, "");
};

export const trimSpacesAndPeriods = (input: string) => {
    return input.replace(/^[ .]+|[ .]+$/g, "");
};

export function trimCharactersFromStartAndEnd(inputString: string, charactersToTrim: string[]): string {
    if (isEmpty(inputString) || isEmpty(charactersToTrim)) {
        return inputString;
    }
    // Trim characters from the beginning
    let startIndex = 0;
    while (startIndex < inputString.length && charactersToTrim.includes(inputString[startIndex])) {
        startIndex++;
    }

    // Trim characters from the end
    let endIndex = inputString.length - 1;
    while (endIndex >= 0 && charactersToTrim.includes(inputString[endIndex])) {
        endIndex--;
    }

    // Return the trimmed string
    return inputString.slice(startIndex, endIndex + 1);
}

export function isEmptyContent(input: string): boolean {
    if (isEmpty(input)) return true;

    const trimmedInput = input.trim();
    return isEmpty(trimmedInput) || trimmedInput === "--" || trimmedInput === "__";
}

export function convertToCapitalCase(input: string): string {
    if (isEmpty(input)) return input;
    return input
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
}
