// eslint-disable-next-line import/no-named-as-default
import request from "graphql-request";
import { isNullOrUndefined } from "@sailes/utilities";

const hasuraEndpoint = `${process.env.HASURA_HOST_PROTOCOL ?? process.env.NEXT_PUBLIC_HASURA_HOST_PROTOCOL ?? "https"}://${process.env.HASURA_HOST ?? process.env.NEXT_PUBLIC_HASURA_HOST}/v1/graphql`;

export const graphqlFetcher = <Data, Variables>(key: any[]) => {
    type Response = Data & { errors?: { message: string }[] };

    const query = key[0];
    const variables = key.length >= 2 && !isNullOrUndefined(key[1]) ? (key[1] as Variables) : {};
    const headers = key.length >= 3 && !isNullOrUndefined(key[2]) ? key[2] : {};

    return request<Response>(hasuraEndpoint, query, variables!, headers).then((data) => {
        if (data.errors) {
            throw data.errors[0];
        }
        return data;
    });
};
