import(/* webpackMode: "eager", webpackExports: ["ForgotPasswordForm"] */ "/vercel/path0/packages/features/authentication/components/forgot-password-form/forgot-password-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginForm"] */ "/vercel/path0/packages/features/authentication/components/login-form/login-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MfaConfirmForm"] */ "/vercel/path0/packages/features/authentication/components/mfa-confirm-form/mfa-confirm-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MfaSetupForm"] */ "/vercel/path0/packages/features/authentication/components/mfa-setup-form/mfa-setup-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewPasswordForm"] */ "/vercel/path0/packages/features/authentication/components/new-password-form/new-password-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ResetPasswordForm"] */ "/vercel/path0/packages/features/authentication/components/reset-password-form/reset-password-form.tsx");
