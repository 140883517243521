import { isArray, isNumber, isObject, isString } from "./is-type";
import { isNullOrUndefined } from "./is-null-or-undefined";

export const isEmpty = (value: any): boolean => {
    if (isNullOrUndefined(value)) {
        return true;
    }

    if (isArray(value)) {
        return value.length === 0;
    } else if (isObject(value)) {
        return Object.keys(value).length === 0;
    } else if (isString(value)) {
        return value.length === 0 || value.trim().length === 0 || value === "";
    } else if (isNumber(value)) {
        return value === 0;
    } else {
        return true;
    }
};
