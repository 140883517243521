import { IOption } from "../../../common/api/models/option";
import { isEmpty } from "@sailes/utilities";

export enum RoleType {
    admin = "admin",
    operations = "operations",
    success = "success",
    user = "user",
    sales = "sales",
}

export enum CompanyRoleType {
    sailer = "sailer",
    captain = "captain",
    commander = "commander",
    admiral = "admiral",
    none = "none",
}

export namespace RoleType {
    export const all: RoleType[] = [RoleType.admin, RoleType.operations, RoleType.success, RoleType.sales, RoleType.user];

    export const internal: RoleType[] = [RoleType.admin, RoleType.operations, RoleType.success, RoleType.sales];

    export const options: IOption[] = [
        { value: RoleType.user, label: "User" },
        { value: RoleType.operations, label: "Operations" },
        { value: RoleType.success, label: "Success" },
        { value: RoleType.admin, label: "Admin" },
        { value: RoleType.sales, label: "Sales" },
    ];
}

export namespace CompanyRoleType {
    export const all: CompanyRoleType[] = [CompanyRoleType.none, CompanyRoleType.sailer, CompanyRoleType.captain, CompanyRoleType.admiral, CompanyRoleType.commander];
    export const privileged: CompanyRoleType[] = [CompanyRoleType.captain, CompanyRoleType.admiral, CompanyRoleType.commander];

    export const options: IOption[] = [
        { value: CompanyRoleType.none, label: "None" },
        { value: CompanyRoleType.sailer, label: "Sailer" },
        { value: CompanyRoleType.captain, label: "Captain" },
        { value: CompanyRoleType.commander, label: "Commander" },
        { value: CompanyRoleType.admiral, label: "Admiral" },
    ];

    export const userOptions: IOption[] = [
        { value: CompanyRoleType.sailer, label: "Sailer" },
        { value: CompanyRoleType.captain, label: "Captain" },
    ];

    export function value(role: CompanyRoleType | string): number {
        switch (role) {
            case CompanyRoleType.sailer:
                return 0;
            case CompanyRoleType.captain:
                return 100;
            case CompanyRoleType.commander:
                return 200;
            case CompanyRoleType.admiral:
                return 300;
            default:
                return 0;
        }
    }

    export function mostPrivilegedRole(roles: CompanyRoleType[]): CompanyRoleType {
        if (isEmpty(roles)) {
            return CompanyRoleType.none;
        }
        return roles.reduce((prev, current) => (value(prev) > value(current) ? prev : current), CompanyRoleType.none);
    }

    export function leastPrivilegedRole(roles: CompanyRoleType[]): CompanyRoleType {
        if (isEmpty(roles)) {
            return CompanyRoleType.none;
        }
        return roles.reduce((prev, current) => (value(prev) < value(current) ? prev : current), CompanyRoleType.admiral);
    }
}
