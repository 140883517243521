import { useCallback, useContext, useMemo } from "react";
import { useSearchParams } from "next/navigation";
import { ISession } from "../api/models/session";
import { AuthenticationDirector } from "../api/logic/authentication-director";
import { AuthenticationContext } from "../contexts/authentication";

export interface UseAuthenticationDirectorReturnValue {
    next(session: ISession): Promise<string>;
}

export function useAuthenticationDirector(): UseAuthenticationDirectorReturnValue {
    const { state } = useContext(AuthenticationContext);

    const authenticationDirector = useMemo(() => new AuthenticationDirector(), []);
    const searchParams = useSearchParams();

    const next = useCallback(
        async (session: ISession) => {
            return (
                (await authenticationDirector.next({
                    callbackUrl: searchParams?.get("callbackUrl") || "/",
                    session: session,
                    app: state.app,
                })) ??
                searchParams?.get("callbackUrl") ??
                "/"
            );
        },
        [authenticationDirector, searchParams, state.app]
    );

    return {
        next,
    };
}
