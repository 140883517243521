import { useMemo } from "react";
import { useReadLocalStorage } from "usehooks-ts";
import { ConsentEventType, IConsentManagementData } from "../api/models/consent-type";

export function useConsentManagement(bypass = false): IConsentManagementData | undefined {
    const consentCacheData = useReadLocalStorage<any>("TERMLY_API_CACHE");

    const cookieConsentData = useMemo(() => {
        if (bypass) return undefined;
        return consentCacheData?.TERMLY_COOKIE_CONSENT;
    }, [consentCacheData, bypass]);

    return useMemo<IConsentManagementData | undefined>(() => {
        if (bypass) return undefined;

        return {
            createdAt: new Date(cookieConsentData?.created_at) ?? new Date(),
            value: {
                advertising: cookieConsentData?.value.advertising ?? false,
                analytics: cookieConsentData?.value.analytics ?? false,
                performance: cookieConsentData?.value.performance ?? false,
                documentVersionId: cookieConsentData?.value.document_version_Id ?? -1,
                essential: cookieConsentData?.value.essential ?? true,
                socialNetworking: cookieConsentData?.value.social_networking ?? false,
                eventType: cookieConsentData?.value.event_type ?? ConsentEventType.browser,
                id: cookieConsentData?.value.id ?? -1,
                templateVersionId: cookieConsentData?.value.template_version_id ?? -1,
                unclassified: cookieConsentData?.value.unclassified ?? false,
                visitorId: cookieConsentData?.value.visitor_id ?? -1,
                visitor: cookieConsentData?.value.visitor ?? { uuid: "" },
            },
        };
    }, [cookieConsentData, bypass]);
}
