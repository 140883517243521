"use client";

import { convertToSnakeCase, isNullOrUndefined, noop } from "@sailes/utilities";
import React, { useState, ChangeEvent, HTMLInputTypeAttribute, ReactElement } from "react";
import clsx from "clsx";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { FormInput, FormInputProps } from "../form-input";

export enum TextInputVariant {
    default = "DEFAULT",
    darkDefault = "DARK_DEFAULT",
    outlined = "OUTLINED",
    standard = "STANDARD",
    none = "NONE",
}

export interface TextInputProps extends Omit<FormInputProps, "children"> {
    defaultValue?: string;
    placeholder?: string;
    disabled?: boolean;
    type?: HTMLInputTypeAttribute;
    value?: string;
    leftAside?: ReactElement;
    rightAside?: ReactElement;
    className?: string;
    inputClassName?: string;
    autoComplete?: "off" | "on";
    autoFocus?: boolean;
    step?: string | number;
    helper?: string;
    leftAdornment?: ReactElement;
    rightAdornment?: ReactElement;
    variant?: TextInputVariant | typeof TextInputVariant[keyof typeof TextInputVariant] | string;
    inputName?: string;
    spellCheck?: boolean;
    separator?: boolean;

    onChange?(value: string): void;
    onChangeEvent?(event: ChangeEvent): void;
    onKeydown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export function TextInput(props: TextInputProps) {
    const {
        label,
        error,
        type,
        defaultValue,
        placeholder,
        disabled,
        rightAside,
        className,
        inputClassName,
        autoComplete,
        autoFocus,
        step,
        helper,
        leftAdornment,
        rightAdornment,
        variant = "DEFAULT",
        inputName,
        spellCheck = false,
        separator = false,
    } = props;

    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <FormInput label={label} error={error} helper={helper}>
            <div className={"relative flex w-full items-end"}>
                <div className={clsx("flex-grow", className)}>
                    {!isNullOrUndefined(leftAdornment) && <div className={stylesForLeftAdornment(variant)}>{leftAdornment}</div>}

                    <input
                        type={showPassword ? "text" : type ?? "text"}
                        data-testid={inputName ?? convertToSnakeCase(label ?? "text_input")}
                        name={inputName ?? convertToSnakeCase(label ?? "text_input")}
                        id={convertToSnakeCase(label ?? "text_input")}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        spellCheck={spellCheck}
                        className={clsx(
                            "peer",
                            stylesForVariant(variant, !(isNullOrUndefined(error) || disabled)),
                            !isNullOrUndefined(leftAdornment) ? (separator ? "pl-10" : "pl-8") : "",
                            type === "password" || !isNullOrUndefined(rightAdornment) ? (separator ? "pr-10" : "pr-8") : "",
                            inputClassName
                        )}
                        value={props?.value}
                        onChange={(event) => {
                            props.onChange ? props.onChange!(event.target.value) : noop();
                            props.onChangeEvent ? props.onChangeEvent!(event) : noop();
                        }}
                        placeholder={placeholder}
                        autoComplete={autoComplete ?? "off"}
                        autoFocus={autoFocus ?? false}
                        step={step}
                        onKeyDown={props.onKeydown}
                        aria-invalid={!isNullOrUndefined(error)}
                        aria-errormessage={`${convertToSnakeCase(label ?? "text_input")}_error`}
                    />
                    {!isNullOrUndefined(leftAdornment) && separator && <div className="absolute left-8 top-0 bottom-0 w-px h-full bg-[#DADCD5] peer-focus:bg-[#78716C]" />}

                    {type === "password" && (
                        <div className={clsx(stylesForRightAdornment(variant), "hover:cursor-pointer")} onClick={toggleShowPassword}>
                            {showPassword ? <EyeSlashIcon className="text-gray-900 opacity-40" /> : <EyeIcon className="text-gray-900 opacity-40" />}
                        </div>
                    )}
                    {!isNullOrUndefined(rightAdornment) && separator && <div className="absolute right-8 top-0 bottom-0 w-px h-full bg-[#DADCD5] peer-focus:bg-[#78716C]" />}
                    {!isNullOrUndefined(rightAdornment) && type !== "password" && (
                        <div className={clsx(stylesForRightAdornment(variant), "hover:cursor-pointer")}>{rightAdornment}</div>
                    )}
                </div>

                {!isNullOrUndefined(rightAside) && <div className={"h-full"}>{rightAside}</div>}
            </div>
        </FormInput>
    );
}

function stylesForVariant(variant: TextInputProps["variant"], isError = false) {
    switch (variant) {
        case TextInputVariant.default:
            return clsx(
                "bg-white block text-gray-900 w-full rounded-md shadow-sm md:text-sm focus:ring-0.5",

                isError ? "border-red-300 focus:border-red-500 focus:ring-red-500" : "border-gray-300 focus:border-stone-500 focus:ring-stone-500"
            );
        case TextInputVariant.darkDefault:
            return clsx(
                "bg-stone-200 block text-gray-900 w-full rounded-md shadow-sm md:text-sm focus:ring-1",

                isError ? "border-red-300 focus:border-red-500 focus:ring-red-500" : "border-gray-300 focus:border-stone-800 focus:ring-stone-800"
            );
        case TextInputVariant.outlined:
            return clsx(
                "bg-white block text-gray-900 w-full rounded-md shadow-sm md:text-sm focus:ring-0.5",

                isError ? "border-red-300 focus:border-red-500 focus:ring-red-500" : "border-gray-300 focus:border-stone-500 focus:ring-stone-500"
            );
        case TextInputVariant.standard:
            return clsx(
                "w-full border-0 border-b-2 border-grey-500 px-0 pb-2  outline-0 outline-none outline-offset-0 ring-0 ring-offset-0 focus:ring-0 focus:ring-offset-0 focus:ring-offset-transparent focus:ring-transparent",

                isError ? "border-red-300 focus:border-red-500 focus:ring-red-500" : "focus:border-stone-950 border-gray-300 focus:border-stone-800 focus:ring-stone-800"
            );
        default:
            return clsx("bg-stone-100 p-0 w-full border-none hover:border-none focus:ring-0 rouned-none outline-offset-0");
    }
}

function stylesForLeftAdornment(variant: TextInputProps["variant"]) {
    switch (variant) {
        case TextInputVariant.default:
            return clsx("flex justify-center items-center absolute bottom-0 top-0 my-auto left-2 w-5 h-4");
        case TextInputVariant.darkDefault:
            return clsx("flex justify-center items-center absolute bottom-0 top-0 my-auto left-2 w-5 h-4");
        case TextInputVariant.outlined:
            return clsx("flex justify-center items-center absolute bottom-0 top-0 my-auto left-2 w-5 h-4");
        case TextInputVariant.standard:
            return clsx("flex justify-center items-center absolute bottom-0 top-0 my-auto left-2 w-5 h-4");
        default:
            return clsx("flex justify-center items-center absolute bottom-0 top-0 my-auto left-2 w-5 h-4");
    }
}

function stylesForRightAdornment(variant: TextInputProps["variant"]) {
    switch (variant) {
        case TextInputVariant.default:
            return clsx("flex justify-center items-center absolute bottom-0 top-0 my-auto right-2 w-5 h-4");
        case TextInputVariant.darkDefault:
            return clsx("flex justify-center items-center absolute bottom-0 top-0 my-auto right-2 w-5 h-4");
        case TextInputVariant.outlined:
            return clsx("flex justify-center items-center absolute bottom-0 top-0 my-auto right-2 w-5 h-4");
        case TextInputVariant.standard:
            return clsx("flex justify-center items-center absolute bottom-0 top-0 my-auto right-2 w-5 h-4");
        default:
            return clsx("flex justify-center items-center absolute bottom-0 top-0 my-auto right-2 w-5 h-4");
    }
}
