import { AuthenticationAction, AuthenticationActionType } from "./authentication-actions";
import { AuthenticationState } from "./authentication-state";

export function authenticationReducer(state: AuthenticationState, action: AuthenticationAction): AuthenticationState {


    switch (action.type) {

        case AuthenticationActionType.updateEmail:
            return {
                ...state,
                email: action.payload?.email
            }

        default:
            return {
                ...state,
                ...action.payload
            }

    }

}
