import { isNullOrUndefined } from "@sailes/utilities";
import { VercelKV, createClient } from "@vercel/kv";

class KVClient {
    private client: VercelKV;

    constructor() {
        this.client = createClient({
            url: process.env.KV_REST_API_URL,
            token: process.env.KV_REST_API_TOKEN,
        });
    }

    async set(key: string, value: any): Promise<any> {
        return await this.client.set(key, value);
    }

    async get(key: string): Promise<any> {
        return !isNullOrUndefined(key) ? await this.client.get(key) : null;
    }
}

export default KVClient;
