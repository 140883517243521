// @ts-ignore
import axios from "axios";
import { isNullOrUndefined } from "@sailes/utilities";

export const httpFetcher = (key: any[]) => {
    const url = key[0];
    const headers = key.length >= 2 && !isNullOrUndefined(key[1]) ? key[1] : {};
    const params = key.length >= 3 && !isNullOrUndefined(key[2]) ? key[2] : {};
    const cancelToken = key.length >= 4 && !isNullOrUndefined(key[3]) ? key[3] : null;

    return axios
        .get(url, {
            headers,
            params,
            cancelToken,
        })
        .then((res: any) => res.data);
};
