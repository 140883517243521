import { useEffect } from "react";
import { logger } from "@sailes/logging";
import { datadogRum } from "@datadog/browser-rum";
import { useConsentManagement } from "../../consent-management/hooks/use-consent-management";
import { useBoolean } from "usehooks-ts";

export function useRumInit(requiresConsent = true) {
    const consent = useConsentManagement(!requiresConsent);
    const isInitialized = useBoolean(false);

    useEffect(() => {
        if ((requiresConsent && !consent.value.analytics) || isInitialized.value) {
            return;
        }

        isInitialized.setTrue();

        logger.debug({ domain: "packages/features/analytics/hooks/use-rum-init.tsx", message: "Initializing RUM" });

        datadogRum.init({
            applicationId: process.env.NEXT_PUBLIC_DATA_DOG_APP_ID,
            clientToken: process.env.NEXT_PUBLIC_DATA_DOG_CLIENT_TOKEN,
            site: process.env.NEXT_PUBLIC_DATA_DOG_SITE,
            service: process.env.NEXT_PUBLIC_DATA_DOG_SERVICE,
            env: process.env.NEXT_PUBLIC_APP_ENV,
            version: process.env.NEXT_PUBLIC_VERSION,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: "mask-user-input",
            startSessionReplayRecordingManually: true,
            enableExperimentalFeatures: ["feature_flags"],
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consent?.value.analytics, isInitialized.value]);
}
