"use client";

import { Form, Formik } from "formik";
import { useRouter, useSearchParams } from "next/navigation";
import React, { useContext, useState } from "react";
import { useBoolean } from "usehooks-ts";
import { logger } from "@sailes/logging";
import { AuthenticationActionType, AuthenticationContext } from "../../contexts/authentication";
import { newPasswordSchema } from "../../api/models/new-password-schema";
import { TextInput } from "../../../common/components/text-input";
import { Button } from "../../../common/components/button";
import { ButtonSize } from "../../../common/components/button";
import { confirmSignIn } from "aws-amplify/auth";

export function NewPasswordForm() {
    const [submissionError, setSubmissionError] = useState();
    const { replace } = useRouter();
    const searchParams = useSearchParams();
    const { state, dispatch } = useContext(AuthenticationContext);
    const isInitialSubmit = useBoolean(true);

    return (
        <Formik
            validationSchema={newPasswordSchema}
            initialValues={{
                password: "",
                confirmPassword: "",
            }}
            validateOnChange={!isInitialSubmit}
            onSubmit={async (values, formikHelpers) => {
                const { setSubmitting } = formikHelpers;

                try {
                    const { nextStep } = await confirmSignIn({
                        challengeResponse: values.password,
                    });

                    if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
                        replace(`/auth/mfa-confirm?callbackUrl=${searchParams?.get("callbackUrl") ?? "/"}`);
                    } else if (nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_TOTP_SETUP") {
                        dispatch({
                            type: AuthenticationActionType.updateMfaSecret,
                            payload: {
                                mfaSecret: nextStep.totpSetupDetails.sharedSecret,
                                totpUrl: nextStep.totpSetupDetails.getSetupUri("Starboard", state.email).href,
                            },
                        });

                        replace(`/auth/mfa-setup?callbackUrl=${searchParams?.get("callbackUrl") ?? "/"}`);
                    }
                } catch (error) {
                    setSubmitting(false);
                    setSubmissionError(error.message);
                    logger.error({ error, message: error.message + "for Cognito User" });
                }
            }}
        >
            {(formikProps) => {
                const { values, isSubmitting, setValues, errors } = formikProps;

                return (
                    <Form>
                        <div className="space-y-4">
                            <TextInput
                                label={"New Password"}
                                type="password"
                                error={errors.password}
                                onChange={(value) =>
                                    setValues({
                                        ...values,
                                        password: value,
                                    })
                                }
                            />

                            <TextInput
                                label={"Confirm Password"}
                                type="password"
                                error={errors.confirmPassword}
                                onChange={(value) =>
                                    setValues({
                                        ...values,
                                        confirmPassword: value,
                                    })
                                }
                            />
                        </div>

                        <div className="flex flex-col space-y-6 items-center justify-center mt-10">
                            <Button label={"Set Password"} busy={isSubmitting} onClick={isInitialSubmit.setFalse} type={"submit"} size={ButtonSize.large} className={"w-full"} />

                            {submissionError && <p className={"text-sm text-red-500 mt-5"}>{submissionError}</p>}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
