import { IErrorLog, ILog, ILogger } from "./types/logger";

let _logger: ILogger = {
    debug: (log: ILog) => console.log(log),
    error: (log: IErrorLog) => console.error(log),
    info: (log: ILog) => console.info(log),
    warn: (log: ILog) => console.warn(log),
};

if (typeof window !== "undefined" && process.env.NODE_ENV === "production") {
    try {
        const datadogLogs = require("@datadog/browser-logs").datadogLogs;

        datadogLogs.init({
            clientToken: process.env.NEXT_PUBLIC_DATA_DOG_CLIENT_TOKEN,
            site: process.env.NEXT_PUBLIC_DATA_DOG_SITE,
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
        });

        _logger = {
            debug: (log: ILog) => {
                process.env.NODE_ENV === "development" && console.debug(log);
                datadogLogs.logger.debug(log.message, { ...log });
            },
            error: (log: IErrorLog) => {
                process.env.NODE_ENV === "development" && console.error(log);
                datadogLogs.logger.error(log.message, log, log.error);
            },
            info: (log: ILog) => {
                process.env.NODE_ENV === "development" && console.info(log);
                datadogLogs.logger.info(log.message, log);
            },
            warn: (log: ILog) => {
                process.env.NODE_ENV === "development" && console.warn(log);
                datadogLogs.logger.warn(log.message, log);
            },
        };
    } catch (error) {
        console.error(error);
    }
}

export const logger = _logger;
