import { useBoolean } from "usehooks-ts";
import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { logger } from "@sailes/logging";

export function useSessionRecording(): void {
    const isRecording = useBoolean(false);

    useEffect(() => {
        if (isRecording.value) {
            return;
        }

        isRecording.setTrue();

        if (process.env.NODE_ENV === "production") {
            logger.debug({ domain: "packages/features/analytics/hooks/use-session-recording.tsx", message: "Starting session recording." });
            datadogRum.startSessionReplayRecording();
        } else {
            logger.debug({ domain: "packages/features/analytics/hooks/use-session-recording.tsx", message: "Skipping session recording in development." });
        }
    }, [isRecording]);
}
