"use client";

import { Form, Formik } from "formik";
import { useRouter, useSearchParams } from "next/navigation";
import React, { useContext } from "react";
import { useBoolean } from "usehooks-ts";
import { AuthenticationActionType, AuthenticationContext } from "../../contexts/authentication";
import { forgotPasswordSchema } from "../../api/models/forgot-password-schema";
import { TextInput } from "../../../common/components/text-input";
import { Button } from "../../../common/components/button";
import { ButtonSize, ButtonVariant } from "../../../common/components/button";
import { resetPassword } from "aws-amplify/auth";

export function ForgotPasswordForm() {
    const { push, back } = useRouter();
    const searchParams = useSearchParams();
    const { state, dispatch } = useContext(AuthenticationContext);
    const isInitialSubmit = useBoolean(true);
    return (
        <Formik
            validationSchema={forgotPasswordSchema}
            initialValues={{
                email: state.email ?? "",
            }}
            validateOnChange={!isInitialSubmit.value}
            onSubmit={async (values, formikHelpers) => {

                try {

                    await resetPassword({ username: values.email });

                    dispatch({
                        type: AuthenticationActionType.updateEmail,
                        payload: { email: values.email },
                    });

                    push(`/auth/reset-password?callbackUrl=${searchParams?.get("callbackUrl") ?? "/"}`);
                } catch (e) {
                    formikHelpers.setSubmitting(false);
                }
            }}
        >
            {(formikProps) => {
                const { values, isSubmitting, setValues, errors, initialValues } = formikProps;

                return (
                    <Form>
                        <div className="space-y-4">
                            <TextInput
                                label={"Email"}
                                error={errors.email}
                                defaultValue={initialValues.email}
                                type="email"
                                autoComplete="on"
                                onChange={(value) =>
                                    setValues({
                                        ...values,
                                        email: value,
                                    })
                                }
                                autoFocus={true}
                            />
                        </div>

                        <div className={"flex flex-col space-y-4 items-center justify-center mt-10"}>
                            <Button
                                label={"Request Verification Code"}
                                busy={isSubmitting}
                                onClick={isInitialSubmit.setFalse}
                                type={"submit"}
                                size={ButtonSize.large}
                                className="w-full"
                            />

                            <Button label={"Back to Sign In"} onClick={back} variant={ButtonVariant.link} />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
