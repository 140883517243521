export function getAuthConfiguration(): any {

    return {
        aws_project_region: process.env.AMPLIFY_PROJECT_REGION ?? process.env.NEXT_PUBLIC_AMPLIFY_PROJECT_REGION,
        aws_cognito_identity_pool_id: process.env.COGNITO_IDENTITY_POOL_ID ?? process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID,
        aws_cognito_region: process.env.COGNITO_REGION ?? process.env.NEXT_PUBLIC_COGNITO_REGION,
        aws_user_pools_id: process.env.COGNITO_USER_POOL_ID ?? process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
        aws_user_pools_web_client_id: process.env.COGNITO_USER_POOL_WEB_CLIENT_ID ?? process.env.NEXT_PUBLIC_COGNITO_USER_POOL_WEB_CLIENT_ID,
        oauth: {},
        aws_cognito_username_attributes: ["EMAIL"],
        aws_cognito_social_providers: [],
        aws_cognito_signup_attributes: ["EMAIL"],
        aws_cognito_mfa_configuration: "ON",
        aws_cognito_mfa_types: ["SMS", "TOTP"],
        aws_cognito_password_protection_settings: {
            passwordPolicyMinLength: 8,
            passwordPolicyCharacters: [],
        },
        aws_cognito_verification_mechanisms: ["EMAIL"],
    };

}
