export enum ConsentEventType {
    browser = 'browsewrap',
}

export interface IConsentManagementData {
    createdAt: Date
    value: {
        id: number
        advertising: boolean
        analytics: boolean
        documentVersionId: number
        essential: boolean
        eventType: ConsentEventType
        performance: boolean
        socialNetworking: boolean
        templateVersionId: number
        unclassified: boolean
        visitorId: number
        visitor: {
            uuid: string
        }
    }
}