import * as React from "react";
import type { SVGProps } from "react";
const SvgMail = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16" {...props}>
        <path
            stroke={props.stroke ?? "#1C1D16"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeOpacity={props.strokeOpacity ?? 0.45}
            strokeWidth={1.5}
            d="M1.667 3.95 8.47 8.711c.55.386.826.579 1.126.653.264.066.541.066.806 0 .3-.074.575-.267 1.126-.653l6.804-4.763M5.667 14.783h8.666c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 0 0 1.093-1.092c.272-.535.272-1.235.272-2.635V5.449c0-1.4 0-2.1-.272-2.635a2.5 2.5 0 0 0-1.093-1.092c-.535-.273-1.235-.273-2.635-.273H5.667c-1.4 0-2.1 0-2.635.273a2.5 2.5 0 0 0-1.093 1.092c-.272.535-.272 1.235-.272 2.635v5.334c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.092c.534.273 1.234.273 2.635.273Z"
        />
    </svg>
);
export default SvgMail;
